<template>
  <v-btn
    :class="defaultClass + className + (outset ? ' outset' : '')"
    :color="color"
    :disabled="disabled"
    :icon="icon"
    :loading="loading"
    :outlined="outlined"
    :text="text"
    :width="width"
    @click="onClick"
  >
    <slot name="text" />
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    className: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    outset: {
      type: Boolean,
      default: true
    },
    text: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '180'
    }
  },
  data() {
    return {
      defaultClass: 'rounded-xl '
    };
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    }
  }
};
</script>
