<template>
  <v-text-field
    :background-color="'#fff'"
    :class="className"
    :clearable="clearable"
    :counter="counter"
    :disabled="disabled"
    :hint="hint"
    :label="label"
    :maxlength="maxlength"
    :outlined="outlined"
    :persistent-hint="persistentHint"
    :persistent-placeholder="persistentPlaceholder"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :readonly="readonly"
    :ref="refName"
    :rules="rules"
    :suffix="suffix"
    :type="type"
    :value="value"
    @blur="onBlur"
    @input="onInput"
    @keypress="onKeypress"
  >
    <template v-if="showHelpIcon" v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span v-html="nl2br(helpTooltip)" />
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';

export default {
  name: 'BaseTextField',
  mixins: [AppMethodsMixin],
  props: {
    className: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, Number, String],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    helpTooltip: {
      type: String,
      default: undefined
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: undefined
    },
    outlined: {
      type: Boolean,
      default: true
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    prependInnerIcon: {
      type: String,
      default: undefined
    },
    prependInnerIconTooltop: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    refName: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    showHelpIcon: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [Number, String],
      default: ''
    }
  },

  methods: {
    onBlur(e) {
      this.$emit('blur', e);
    },
    onInput(e) {
      this.$emit('input', e);
    },
    onKeypress(e) {
      this.$emit('keypress', e);
    }
  }
};
</script>
