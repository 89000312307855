<template>
  <index-presenter
    :valid="valid"
    :password="password"
    :password-confirm="passwordConfirm"
    @on-input="onInput"
    @on-submit="onSubmit"
  />
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import IndexPresenter from '@/components/pages/password_reset/index/IndexPresenter';

export default {
  name: 'IndexContainer',
  components: { IndexPresenter },
  mixins: [AppMethodsMixin],
  data() {
    return {
      valid: false,
      password: '',
      passwordConfirm: ''
    };
  },
  methods: {
    onInput(e) {
      const KEY = Object.keys(e)[0];
      this.$set(this, KEY, e[KEY]);
    },
    onSubmit() {
      this.showLoadingOverlay(async () => {
        await this.$store.dispatch('passwordResets/updatePassword', {
          token: this.$route.params.token,
          password: this.password,
          passwordConfirm: this.passwordConfirm
        });
      });
    }
  }
};
</script>
