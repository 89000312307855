<template>
  <v-main>
    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          xs12
          md8
          style="margin-bottom: 80px; max-width: 850px;"
        >
          <h2 class="mb-10 primary--text">
            新しいパスワードを設定
          </h2>
          <v-card
            :style="styles.bg"
            :class="'outset'"
          >
            <v-row class="text-center pt-4">
              <img
                src="@/assets/images/logo_lg.png"
                style="max-width: 400px; width: 100%;"
                class="ma-auto"
              >
            </v-row>
            <v-row class="pt-0 pb-8 mt-0">
              <v-col class="text-center">
                <p>半角英数字を含む8文字以上のパスワードを設定してください。</p>
              </v-col>
              <v-col
                cols="12"
                class="pt-0 pb-3 pr-10"
              >
                <div>
                  <v-card-text class="pt-0">
                    <v-form
                      ref="form"
                      v-model="isValid"
                    >
                      <base-text-field
                        :counter="100"
                        :label="'パスワード'"
                        :prepend-icon="'mdi-lock'"
                        :rules="[maxLength(100) ,required, passwordFormat, passwordLength]"
                        :type="'password'"
                        :value="password"
                        @input="onInput({ password: $event })"
                      />
                      <base-text-field
                        class="mt-6"
                        :label="'パスワード（確認用）'"
                        :prepend-icon="'mdi-lock'"
                        :rules="[required, validPasswordConfirm]"
                        :type="'password'"
                        :value="passwordConfirm"
                        @input="onInput({ passwordConfirm: $event })"
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-row class="text-center">
                      <v-col
                        cols="12"
                        class="pt-0"
                      >
                        <base-button
                          :outlined="true"
                          :width="'280'"
                          @click="onSubmit"
                        >
                        <template #text>
                          設定
                        </template>
                        </base-button>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <base-button
                          :color="'default'"
                          :outlined="true"
                          :width="'280'"
                          @click="$router.push({ name: 'Login' })"
                        >
                        <template #text>
                          ログイン画面へ
                        </template>
                        </base-button>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton';
import BaseTextField from '@/components/atoms/BaseTextField';
import FormRulesMixin from '@/mixins/FormRulesMixin';

export default {
  name: 'IndexPresenter',
  components: { BaseButton, BaseTextField },
  mixins: [FormRulesMixin],
  props: {
    valid: {
      type: Boolean,
      default: false
    },
    password: {
      type: String,
      default: undefined
    },
    passwordConfirm: {
      type: String,
      default: undefined
    }
  },
  computed: {
    styles() {
      return {
        bg: {
          color: this.$vuetify.theme.themes.light.primary,
          'border-radius': '20px',
          'background-color': this.$vuetify.theme.themes.light.background
        }
      };
    },
    isValid: {
      get() {
        return this.valid;
      },
      set(v) {
        this.$emit('on-input', { valid: v });
      }
    }
  },
  methods: {
    validPasswordConfirm(passwordConfirm) {
      return this.password == passwordConfirm || 'パスワードと確認用のパスワードが一致しません';
    },
    onInput(e) {
      this.$emit('on-input', e);
    },
    onSubmit() {
      this.$emit('on-submit');
    }
  }
};
</script>
