const PASSWORD_FORMAT = /^(?=.*?[a-z])(?=.*?\d){8,100}/i;

export default {
  methods: {
    /** 必須ルール */
    required(value) {
      if (isFinite(value)) {
        value = String(value);
      }
      return (value||'').replace(/[\x20\u3000]/g, '').length > 0 || '必須項目です';
    },

    /** 入力文字数のルール */
    maxLength(maxlength) {
      return (value) => {
        return (value||'').length <= maxlength || `${maxlength}文字以内で入力してください`;
      };
    },

    /** 入力数値の最大値ルール */
    maxNumber(maxNumber) {
      return (value) => {
        return value <= maxNumber || `${maxNumber}以下の数値を入力してください`;
      };
    },

    /** パスワードのフォーマットチェック */
    passwordFormat(value) {
      return PASSWORD_FORMAT.test(value) || '半角英数を含んだパスワードにしてください';
    },

    passwordLength(value) {
      return /^.{8,100}/.test(value) || '8文字以上 100文字以下のパスワードを設定してください';
    }
  }
};
